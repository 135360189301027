<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="900"   scrollable>
            <v-card>

              <v-toolbar dark>
                <v-card-title class="headline">
                  {{`${edit?'Update':"Add"} request for leave`}}
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="cancelForm">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

                <v-card-text style="max-height: 500px">
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                    v-model="leave_request.user_id"
                                    :items="users"
                                    :loading="isBusy"
                                    outlined
                                    dense
                                    :search-input.sync="searchName"
                                    class="mt-3"
                                    item-value="id"
                                    label="User CRN or email"
                            >
                                <template slot="selection" slot-scope="data">
                                    <!-- HTML that describe how select should render selected items -->
                                    {{ data.item.personal_id }} - {{ data.item.full_name }}- {{ data.item.email }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    <!-- HTML that describe how select should render items when the select is open -->
                                    {{ data.item.personal_id }} - {{ data.item.full_name }}- {{ data.item.email }}
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-select label="Categories"
                                      :items="leave_categories"
                                      item-text="title"
                                      item-value="id"
                                      @change="checkCategoryChange"
                                      :loading="categoryLoading"
                                      v-model="leave_request.leave_category_id" outlined dense>
                            </v-select>
                            <span class="text-danger" v-if="$v.leave_request.leave_category_id.$error">Category is required</span>
                        </v-col>
                        <v-col v-if="selectedCategory  && selectedCategory[0].description">
                            <p v-html="selectedCategory[0].description"></p>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Title" v-model="leave_request.title" outlined dense>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.leave_request.title.$error">Title is required</span>
                        </v-col>
                        <v-col cols="12">
                            <label for="description">Description</label>
                            <ckeditor id="description" v-model="leave_request.description"
                                      :config="editorConfig"></ckeditor>
                            <span class="text-danger" v-if="$v.leave_request.description.$error">Description is required</span>

                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="leave_from"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                            v-model="leave_request.leave_start_date"
                                            label="Leave from"
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            outlined
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                        :min="today"
                                        v-model="leave_request.leave_start_date"
                                        @input="leave_from = false"
                                ></v-date-picker>
                            </v-menu>
                            <span class="text-danger" v-if="$v.leave_request.leave_start_date.$error">This information is required</span>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="leave_to"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                            v-model="leave_request.leave_end_date"
                                            label="Leave ends"
                                            append-icon="mdi-calendar"
                                            readonly
                                            outlined
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                        :min="today"
                                        v-model="leave_request.leave_end_date"
                                        @input="leave_to = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="3">
                            <v-checkbox v-model="leave_request.is_half" label="Half day"></v-checkbox>
                        </v-col>
                        <v-col md="4" cols="12">
                            <v-file-input
                                    label="Attachment 1"
                                    prepend-inner-icon="mdi-file"
                                    prepend-icon=""
                                    outlined
                                    v-model="leave_request.attachment_1"
                                    dense
                            ></v-file-input>
                        </v-col>
                        <v-col md="4" cols="12">
                            <v-file-input
                                    label="Attachment 2"
                                    outlined
                                    prepend-inner-icon="mdi-file"
                                    prepend-icon=""
                                    v-model="leave_request.attachment_2"
                                    dense
                            ></v-file-input>
                        </v-col>
                        <v-col md="4" cols="12">
                            <v-file-input
                                    label="Attachment 3"
                                    outlined
                                    prepend-inner-icon="mdi-file"
                                    prepend-icon=""
                                    v-model="leave_request.attachment_3"
                                    dense
                            ></v-file-input>
                        </v-col>
<!--                        <v-col cols="3">-->
<!--                            <v-switch v-model="leave_request.is_active" label="Active"></v-switch>-->
<!--                        </v-col>-->
<!--                        <v-col cols="3">-->
<!--                            <v-switch v-model="leave_request.auto_approval" label="Auto Approval"></v-switch>-->
<!--                        </v-col>-->

                    </v-row>

                </v-card-text>
              <v-card-actions>
                <v-col cols="12">
                  <div class="text-right float-right">
                    <v-btn depressed @click="cancelForm" class="text-gray btn btn-standard cancel-btn">
                      Cancel
                    </v-btn>
                    <v-btn depressed :loading="isBusy" @click="createOrUpdate"
                           class="text-white ml-2 btn btn-primary ">
                      Save
                    </v-btn>
                  </div>

                </v-col>
              </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import LeaveCategoryService from "@/core/services/leave/leave-category/LeaveCategoryService";
    const leaveCategoryService = new LeaveCategoryService();
    import LeaveRequestService from "@/core/services/leave/leave-request/LeaveRequestService";
    const leaveRequestService = new LeaveRequestService();
    import UserService from "@/core/services/user/UserService";
    const userService = new UserService();

    export default {
        name: 'create-leave-category',
        validations: {
            leave_request: {
                title: {required},
                leave_category_id: {required},
                description: {required},
                leave_start_date: {required},

            }
        },
        data() {
            return {
                users: [],
                academic_class: [],
                leave_categories: [],
                dialog: false,
                edit: false,
                isBusy: false,
                attrs: false,
                today: new Date().toISOString().substr(0,10),
                selectedCategory: null,
                leave_from: false,
                leave_to: false,
                categoryLoading: false,
                applicable_for: [
                    {
                        name: 'Student',
                        value: 'student'
                    },
                    {
                        name: 'Teachers',
                        value: 'teacher'
                    }, {
                        name: 'Staff',
                        value: 'staff'
                    },
                    {
                        name: 'All',
                        value: 'all'
                    }
                ],
                editorConfig: {
                  versionCheck: false,
                    toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
                },
                leave_request: {
                    title: null,
                    leave_category_id: null,
                    description: null,
                    leave_start_date: null,
                    leave_end_date: null,
                    attachment_1: null,
                    attachment_2: null,
                    attachment_3: null,
                    is_half: false,
                    is_active: true,
                    requested_by:'admin',
                },
                searchName: null,
                search: {
                    name: '',
                    limit: 5,
                },
            };
        },
        mounted() {

        },
        watch: {
            searchName(newValue, oldValue) {
                this.search.name = newValue;
                  // Lazily load input items
                this.getUsers();

            },
        },
        methods: {
            getUsers() {
                this.isBusy = true;
                userService.paginate(this.search).then(response => {
                    this.isBusy = false;
                    this.users = response.data.data;
                    this.users.map(ele => {
                        if (ele.personal_id && ele.personal_id != undefined && ele.personal_id != null) {
                            ele.display_text = `${ele.personal_id} - ${ele.full_name} - ${ele.email}`
                            this.users.push(ele)
                        }
                    })
                    // this.page = response.data.meta.current_page;
                    // this.total = response.data.meta.total;
                    // this.perPage = parseInt(response.data.meta.per_page);
                    // alert(this.length)
                });
            },
            checkCategoryChange(){
                 this.selectedCategory = this.leave_categories.filter(leave => {
                    return leave.id === this.leave_request.leave_category_id;
                })
            },
            showModal(id = null) {
                if (id) {
                    this.edit = true
                    this.__getSingle(id)
                }else{
                    this.resetForm();
                }
                this.dialog = true;
                this.__getCategory();
            },
            hideModal() {
                this.dialog = false;
            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            }, __getSingle(id) {
                leaveRequestService.show(id).then(response => {
                    this.leave_request = response.data.leave_request
                })
            },
            __getCategory() {
                this.categoryLoading = true;
                leaveCategoryService.paginate().then(response => {
                    this.leave_categories = response.data.data
                }).catch(error=>{
                    console.log(error)
                }).finally(()=>{
                    this.categoryLoading = false;
                })
            },

            generateFd() {
                // console.log(this.leave_request)
                let fd = new FormData();
                for (let key in this.leave_request) {
                    if (key == "is_half" && this.leave_request["is_half"]) {
                        fd.append("is_half", this.leave_request ? 1 : 0);
                    } else {
                        if (this.leave_request[key] != null) {
                            fd.append(key, this.leave_request[key]);
                        }
                    }
                }
                return fd;
            },
            createOrUpdate() {

                this.$v.leave_request.$touch();
                if (this.$v.leave_request.$error) {
                    setTimeout(() => {
                        this.$v.leave_request.$reset();
                    }, 3000);
                } else {
                        let fd = this.generateFd();
                    if (this.edit) {
                        this.__update(fd);
                    } else {
                        this.__create(fd);
                    }
                }
            },
            __update(fd) {
                this.isBusy = true;
                leaveRequestService
                    .update(this.leave_request.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");
                        this.$emit('refresh_leave_request');
                        this.hideModal();
                        this.resetForm();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },


            __create(fd) {
                this.isBusy = true;
                leaveRequestService
                    .store(fd)
                    .then((response) => {
                        // console.log(response)
                        this.isBusy = false;
                        this.resetForm();
                        this.hideModal();
                        this.$emit('refresh_leave_request');
                        this.$snotify.success("Leave request added");

                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Unable to create leave request. Please contact admin");
                        // console.log(error);
                    });
            },
            resetForm() {
                this.$v.leave_request.$reset();
                this.edit = false;
                this.leave_request = {
                    title: null,
                    leave_category_id: null,
                    description: null,
                    leave_start_date: null,
                    leave_end_date: null,
                    attachment_1: null,
                    attachment_2: null,
                    attachment_3: null,
                    is_half: false,
                    is_active: true,
                };
            },

            cancelForm(){
                this.resetForm();
                this.hideModal();
            }
        }
    };
</script>
