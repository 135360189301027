<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage leaves</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Leave
                </div>
              </div>
              <div class="breadcrumb-right">
                <v-btn @click="__add()" class="mt-4 btn btn-primary mr-1" dark>
                  <i class="fa fa-plus"></i>
                  Add leave
                </v-btn>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <v-row>
                <v-col>
                  <v-btn-toggle
                    dense
                    mandatory
                    v-model="search.status"
                    @change="__get"
                  >
                    <v-btn value="pending">
                      <span class="hidden-sm-and-down">Pending</span>

                      <v-icon right> fas fa-comment-dots text-warning </v-icon>
                    </v-btn>

                    <v-btn value="approved">
                      <span class="hidden-sm-and-down">Approved</span>

                      <v-icon right> fas fa-check-square text-success </v-icon>
                    </v-btn>

                    <v-btn value="declined">
                      <span class="hidden-sm-and-down">Decline</span>

                      <v-icon right> fas fa-window-close text-danger </v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </div>
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="4" md="3">
                  <v-text-field
                    clearable
                    dense
                    v-on:keyup.enter="__get()"
                    label="Search by CRN, name, email, phone"
                    outlined
                    v-model="search.name"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2" md="3">
                  <v-select
                    v-on:keyup.enter="__get()"
                    :items="user_type"
                    clearable
                    dense
                    item-text="title"
                    item-value="value"
                    label="User type"
                    outlined
                    v-model="search.type"
                  >
                  </v-select>
                </v-col>
                <v-col cols="2" md="3">
                  <v-select
                    v-on:keyup.enter="__get()"
                    :items="leave_catgories"
                    clearable
                    dense
                    item-text="title"
                    item-value="id"
                    label="Categories"
                    outlined
                    v-model="search.leave_category_id"
                  >
                  </v-select>
                </v-col>
                <v-col cols="2" md="3">
                  <v-select
                    v-on:keyup.enter="__get()"
                    :items="academicYears"
                    :loading="loading"
                    clearable
                    dense
                    item-text="title"
                    item-value="id"
                    label="Batch"
                    outlined
                    v-model="search.academic_year_id"
                  >
                  </v-select>
                </v-col>

                <v-col cols="2" md="3">
                  <v-select
                    v-on:keyup.enter="__get()"
                    :items="levels"
                    :loading="loading"
                    @change="getPrograms(search.level_id)"
                    clearable
                    dense
                    item-text="title"
                    item-value="id"
                    label="Level"
                    outlined
                    v-model="search.level_id"
                  >
                  </v-select>
                </v-col>
                <v-col cols="2" md="3">
                  <v-select
                    v-on:keyup.enter="__get()"
                    :items="programs"
                    :loading="loading"
                    @change="getGrades(search.program_id)"
                    clearable
                    dense
                    item-text="title"
                    item-value="id"
                    label="Program"
                    outlined
                    v-model="search.program_id"
                  >
                  </v-select>
                </v-col>
                <v-col cols="2" md="3">
                  <v-select
                    v-on:keyup.enter="__get()"
                    :items="gradesLevels"
                    :loading="loading"
                    @change="
                      getAcademicClasses(
                        search.academic_year_id,
                        search.program_id,
                        search.grade_id
                      )
                    "
                    clearable
                    dense
                    item-text="title"
                    item-value="id"
                    label="Grade"
                    outlined
                    v-model="search.grade_id"
                  >
                  </v-select>
                </v-col>
                <v-col cols="2" md="3">
                  <v-select
                    v-on:keyup.enter="__get()"
                    :items="academic_classes"
                    :loading="loading"
                    clearable
                    dense
                    item-text="title"
                    item-value="id"
                    label="Classes"
                    outlined
                    v-model="search.academic_class_id"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="4" sm="6">
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="auto"
                    offset-y
                    transition="scale-transition"
                    v-model="startDate"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        append-icon="mdi-calendar"
                        clearable
                        dense
                        label="Start Date"
                        outlined
                        readonly
                        v-on:keyup.enter="__get()"
                        v-bind="attrs"
                        v-model="search.start_date"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="startDate = false"
                      v-model="search.start_date"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="auto"
                    offset-y
                    transition="scale-transition"
                    v-model="endDate"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        append-icon="mdi-calendar"
                        clearable
                        dense
                        label="End Date"
                        outlined
                        v-on:keyup.enter="__get()"
                        readonly
                        v-bind="attrs"
                        v-model="search.end_date"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="endDate = false"
                      v-model="search.end_date"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="4" class="text-right">
                  <v-btn @click="__get()" class="btn btn-primary btn-search">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon
                    >&nbsp; Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loadingData">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="loadingData" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loadingData">
                <thead>
                  <tr class="px-3">
                    <th>Request title</th>
                    <th>Requested by</th>
                    <th>Academic info</th>
                    <th class="px-2">Status</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                </thead>
                <tr
                  v-show="leave_requests.length > 0"
                  :key="leave_category.id"
                  v-for="(leave_category, index) of leave_requests"
                >
                  <td class="px-3">
                    <a
                      href="#"
                      class="mr-2"
                      v-if="leave_category.status != 'pending'"
                    >
                      <i
                        :class="
                          leave_category.is_active
                            ? 'dot-active'
                            : 'dot-inactive'
                        "
                        class="fas fa-circle"
                      ></i>
                      {{ leave_category.title }}
                    </a>
                    <a
                      @click="__detail(leave_category)"
                      class="mr-2"
                      href="#"
                      v-else
                    >
                      <i
                        :class="
                          leave_category.is_active
                            ? 'dot-active'
                            : 'dot-inactive'
                        "
                        class="fas fa-circle"
                      ></i>
                      {{ leave_category.title }}
                    </a>
                    <div class="text-secondary mt-1 font-weight-bold">
                      <small>
                        {{
                          leave_category.leave_start_date
                            | moment("Do MMMM YYYY")
                        }}
                      </small>
                      <small v-if="leave_category.leave_end_date">
                        -
                        {{
                          leave_category.leave_end_date | moment("Do MMMM YYYY")
                        }}
                      </small>
                    </div>
                    <div class="mt-2">
                      <span v-if="leave_category.category">
                        <span
                          class="badge badge-secondary ml-2 text-capitalize"
                        >
                          {{ leave_category.category }}
                        </span>
                      </span>
                    </div>
                    <div class="text-secondary">
                      <span class="font-weight-bold">Requested by :</span
                      ><span
                        class="font-weight-medium"
                        v-if="leave_category.requested_by_name"
                      >
                        {{ leave_category.requested_by_name }}</span
                      ><span class="font-weight-medium">
                        ({{ leave_category.requested_by_text }})</span
                      >
                    </div>
                  </td>
                  <td scope="row">
                    <a
                      @click="openSummaryPage(leave_category)"
                      class="text-primary font-weight-bolder text-hover-primary mb-1"
                    >
                      {{ leave_category.user_full_name }}
                    </a>
                    <div class="text-secondary">
                      {{ leave_category.user_personal_id }}
                    </div>
                    <div class="mt-2 ml-0">
                      <span
                        :class="
                          leave_category.user_type == 'student'
                            ? 'badge badge-info'
                            : 'badge badge-success'
                        "
                        >{{ leave_category.user_type }}</span
                      >
                    </div>
                  </td>
                  <td>
                    <div v-if="leave_category.user_setting">
                      <div class="text-secondary">
                        {{ leave_category.user_setting.current_class_title }}
                      </div>
                      <div class="text-secondary">
                        {{ leave_category.user_setting.current_semester_title }}
                      </div>
                      <div class="text-secondary">
                        {{ leave_category.user_setting.program_title }} <br />
                        {{ leave_category.user_setting.academic_year }} <br />
                      </div>
                    </div>
                  </td>
                  <td>
                    <template v-if="leave_category.status">
                      <span
                        :class="leavestatus(leave_category.status)"
                        class="badge text-capitalize"
                      >
                        <!--                                                     leave_category.status?'badge-success':'badge-warning'-->
                        {{
                          leave_category.status
                            ? leave_category.status
                            : "Pending"
                        }}
                      </span>
                    </template>
                  </td>

                  <td class="pr-0 text-center">
                    <b-dropdown
                      no-caret
                      no-flip
                      right
                      size="sm"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      variant="link"
                    >
                      <template v-slot:button-content>
                        <slot>
                          <span>
                            <i class="flaticon-more-1"></i> </span
                        ></slot>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover">
                        <b-dropdown-text
                          class="navi-item"
                          tag="div"
                          v-if="leave_category.status != 'pending'"
                        >
                          <a
                            @click="__viewDetail(leave_category)"
                            class="navi-link"
                            href="#"
                          >
                            <span class="navi-icon">
                              <i class="far fa-eye"></i>
                            </span>
                            <span class="navi-text"> Status </span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text
                          class="navi-item"
                          tag="div"
                          v-if="leave_category.status == 'pending'"
                        >
                          <a
                            @click="__detail(leave_category, 'approved')"
                            class="navi-link"
                            href="#"
                          >
                            <span class="navi-icon">
                              <i class="far fa-check-square text-success"></i>
                            </span>
                            <span class="navi-text"> Approve request</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text
                          class="navi-item"
                          tag="div"
                          v-if="leave_category.status == 'pending'"
                        >
                          <a
                            @click="__detail(leave_category, 'declined')"
                            class="navi-link"
                            href="#"
                          >
                            <span class="navi-icon">
                              <i class="fas fa-window-close text-danger"></i>
                            </span>
                            <span class="navi-text"> Decline request </span>
                          </a>
                        </b-dropdown-text>
                        <!--                                                    <b-dropdown-text class="navi-item" tag="div">-->
                        <!--                                                        <a @click="__detail(leave_category,'cancelled')" class="navi-link"-->
                        <!--                                                           href="#">-->
                        <!--                                                                                                    <span class="navi-icon">-->
                        <!--                                                                                                      <i class=" fas fa-window-close text-danger"></i>-->
                        <!--                                                                                                    </span>-->
                        <!--                                                            <span class="navi-text"> Cancel Request </span>-->
                        <!--                                                        </a>-->
                        <!--                                                    </b-dropdown-text>-->
                        <b-dropdown-text
                          class="navi-item"
                          tag="div"
                          v-if="leave_category.status == 'pending'"
                        >
                          <a
                            @click="__edit(leave_category.id)"
                            class="navi-link"
                            href="#"
                          >
                            <span class="navi-icon">
                              <i class="flaticon-edit"></i>
                            </span>
                            <span class="navi-text"> Edit </span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text
                          class="navi-item"
                          tag="div"
                          v-if="leave_category.status == 'pending'"
                        >
                          <a
                            @click="__delete(leave_category.id)"
                            class="navi-link"
                            href="#"
                          >
                            <span class="navi-icon">
                              <i class="fas fa-trash"></i>
                            </span>
                            <span class="navi-text"> Delete</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>
                  </td>
                </tr>
                <tr v-if="leave_requests.length == 0">
                  <td colspan="5" class="text-center">
                    <strong>Data not available</strong>
                  </td>
                </tr>
              </table>
              <b-pagination
                v-if="leave_requests.length > 0"
                class="pull-right mt-7"
                :per-page="perPage"
                :total-rows="total"
                @input="__get"
                first-number
                last-number
                v-model="page"
              ></b-pagination>
            </div>
          </div>
          <template>
            <v-row>
              <v-dialog
                max-width="800px"
                scrollable
                v-model="leave_detail_dialog"
              >
                <v-card v-if="leave_detail">
                  <v-toolbar flat>
                    <v-card-title>
                      Leave request detail
                      <hr />
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="__reset">
                        <i class="fas fa-times"></i>
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <div class="col-md-6">
                          <h4>
                            {{ leave_detail.user_full_name }} <br />
                            {{ leave_detail.user_type }}
                          </h4>
                          <div class="">
                            <strong> Start Date : </strong>
                            {{
                              leave_detail.leave_start_date
                                | moment("Do MMMM YYYY")
                            }}

                            <p v-if="leave_detail.leave_end_date">
                              <strong>End Date : </strong>
                              {{
                                leave_detail.leave_end_date
                                  | moment("Do MMMM YYYY")
                              }}
                            </p>
                          </div>
                          <h4>{{ leave_detail.title }}</h4>
                          <p v-html="leave_detail.description"></p>
                          <v-row
                            v-if="
                              leave_detail.attachment_1 ||
                              leave_detail.attachment_2 ||
                              leave_detail.attachment_3
                            "
                          >
                            <v-col cols="12"> Attachments </v-col>
                            <v-col cols="12">
                              <v-list-item-group color="primary">
                                <v-list-item v-if="leave_detail.attachment_1">
                                  <v-list-item-icon>
                                    <v-icon> mdi-file</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <a
                                        :href="leave_detail.attachment_one_path"
                                        target="_blank"
                                        >Attachment -
                                        {{ leave_detail.attachment_1 }}</a
                                      >
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="leave_detail.attachment_2">
                                  <v-list-item-icon>
                                    <v-icon> mdi-file</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <a
                                        :href="leave_detail.attachment_two_path"
                                        target="_blank"
                                        >Attachment -
                                        {{ leave_detail.attachment_2 }}</a
                                      >
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="leave_detail.attachment_2">
                                  <v-list-item-icon>
                                    <v-icon> mdi-file</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <a
                                        :href="
                                          leave_detail.attachment_three_path
                                        "
                                        target="_blank"
                                        >Attachment -
                                        {{ leave_detail.attachment_2 }}</a
                                      >
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="col-md-6">
                          <v-row>
                            <v-col cols="12">
                              <label>Remarks on Approval/Disapproval</label>
                              <ckeditor
                                :config="editorConfig"
                                id="description"
                                v-model="leave_detail.processed_comment"
                              ></ckeditor>
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                :items="statuses"
                                dense
                                item-text="title"
                                item-value="value"
                                label="Approval Status"
                                outlined
                                v-model="leave_detail.status"
                              >
                              </v-select>
                            </v-col>
                          </v-row>
                        </div>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-divider></v-divider>
                    <v-col class="text-right" cols="12">
                      <v-btn
                        @click="__reset"
                        class="btn btn-standard cancel-btn"
                        depressed
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        :loading="isBusy"
                        @click="__update"
                        class="text-white ml-2 btn btn-primary"
                        depressed
                      >
                        Save
                      </v-btn>
                    </v-col>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog max-width="800px" v-model="detail_dialog">
                <v-card v-if="leave_detail">
                  <v-toolbar dark>
                    <v-card-title class="headline">
                      Leave request status
                      <hr />
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="detail_dialog = false">
                        <i class="fas fa-times"></i>
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>

                  <v-card-text style="height: 500px">
                    <v-container>
                      <div class="">
                        <span
                          class="badge badge-warning p-2"
                          v-if="leave_detail.status == 'pending'"
                          >{{ leave_detail.status }}</span
                        >
                        <span
                          class="badge badge-success p-2"
                          v-if="leave_detail.status == 'approved'"
                          >{{ leave_detail.status }}</span
                        >
                      </div>
                      <div class="text-secondary h6">
                        <span class="font-weight-bold">Title : </span>
                        <span class="font-weight-medium">
                          {{ leave_detail.title }}
                        </span>
                      </div>
                      <div class="text-secondary h6">
                        <span class="font-weight-bold">Full name : </span>
                        <span class="font-weight-medium">
                          {{ leave_detail.user_full_name }} </span
                        ><span v-if="leave_detail.user_type"
                          >({{ leave_detail.user_type }})</span
                        >
                      </div>

                      <div class="mt-5 mb-5">
                        <div class="text-secondary h6">
                          <span class="font-weight-bold">Start date : </span> :
                          <span class="font-weight-medium">{{
                            leave_detail.leave_start_date
                              | moment("Do MMMM YYYY")
                          }}</span>
                        </div>
                        <div class="text-secondary h6">
                          <span class="font-weight-bold">End date : </span> :
                          <span class="font-weight-medium">{{
                            leave_detail.leave_end_date | moment("Do MMMM YYYY")
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="text-secondary h6 mt-2"
                        v-if="leave_detail.status"
                      >
                        <span class="font-weight-bold">Status : </span>
                        <span
                          class="badge badge-warning p-2"
                          v-if="leave_detail.status == 'pending'"
                          >{{ leave_detail.status }}</span
                        >
                        <span
                          class="badge badge-success p-2"
                          v-if="leave_detail.status == 'approved'"
                          >{{ leave_detail.status_text }}</span
                        >
                        <span
                          class="badge badge-danger p-2"
                          v-if="leave_detail.status == 'declined'"
                          >{{ leave_detail.status_text }}</span
                        >
                        <span
                          class="badge badge-danger p-2"
                          v-if="leave_detail.status == 'canceled'"
                          >{{ leave_detail.status_text }}</span
                        >
                      </div>
                      <hr />
                      <div class="text-secondary">
                        <p class="h6 font-weight-bold">Description</p>
                        <p
                          class="font-weight-regular subtitle-1"
                          v-html="leave_detail.description"
                        ></p>
                      </div>

                      <v-row
                        class="mt-5"
                        v-if="
                          leave_detail.attachment_1 ||
                          leave_detail.attachment_2 ||
                          leave_detail.attachment_3
                        "
                      >
                        <v-col cols="12"> Attachments </v-col>
                        <v-col cols="12">
                          <v-list-item-group color="primary">
                            <v-list-item v-if="leave_detail.attachment_1">
                              <v-list-item-icon>
                                <v-icon> mdi-file</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <a
                                    :href="leave_detail.attachment_one_path"
                                    target="_blank"
                                    >Attachment -
                                    {{ leave_detail.attachment_1 }}</a
                                  >
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="leave_detail.attachment_2">
                              <v-list-item-icon>
                                <v-icon> mdi-file</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <a
                                    :href="leave_detail.attachment_two_path"
                                    target="_blank"
                                    >Attachment -
                                    {{ leave_detail.attachment_2 }}</a
                                  >
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="leave_detail.attachment_2">
                              <v-list-item-icon>
                                <v-icon> mdi-file</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <a
                                    :href="leave_detail.attachment_three_path"
                                    target="_blank"
                                    >Attachment -
                                    {{ leave_detail.attachment_2 }}</a
                                  >
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-col>
                      </v-row>
                      <div
                        class="text-secondary"
                        v-if="leave_detail.processed_comment"
                      >
                        <p class="h6 font-weight-bold">
                          Approved/Declined remarks
                        </p>
                        <p
                          class="font-weight-medium subtitle-1"
                          v-html="leave_detail.processed_comment"
                        ></p>
                      </div>
                      <div
                        class="text-secondary"
                        v-if="leave_detail.processed_date"
                      >
                        <p class="h6 font-weight-bold">
                          Approved/Declined date
                        </p>
                        <p
                          class="font-weight-medium subtitle-1"
                          v-html="leave_detail.processed_date"
                        ></p>
                      </div>
                      <div
                        class="text-secondary"
                        v-if="leave_detail.processed_user"
                      >
                        <p class="h6 font-weight-bold">Approved/Declined By</p>
                        <p
                          class="font-weight-medium subtitle-1"
                          v-html="leave_detail.processed_user"
                        ></p>
                      </div>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-2 cancel-btn"
                      text
                      x-large
                      @click="detail_dialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </template>
          <create-and-update
            @refresh_leave_request="__get"
            ref="leave_category"
          ></create-and-update>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import LeaveRequestService from "@/core/services/leave/leave-request/LeaveRequestService";
import FilterMixin from "@/mixins/FilterMixin";
import LeaveCategoryService from "@/core/services/leave/leave-category/LeaveCategoryService";

const leaveRequestService = new LeaveRequestService();
const leaveCategoryService = new LeaveCategoryService();
export default {
  name: "leave-category",
  display: "Table",
  mixins: [FilterMixin],
  order: 8,
  components: {
    CreateAndUpdate,
  },
  data() {
    return {
      statuses: [
        { title: "Approved", value: "approved" },
        { title: "Decline", value: "declined" },
        { title: "Pending", value: "pending" },
      ],
      user_type: [
        { title: "Student", value: "student" },
        { title: "Teacher", value: "teacher" },
        { title: "Staff", value: "staff" },
      ],
      leave_catgories: [],

      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
          ],
        ],
      },
      leave_requests: [],
      page: null,
      leave_detail: null,
      attrs: false,
      loadingData: false,
      isBusy: false,
      startDate: false,
      endDate: false,
      leave_detail_dialog: false,
      detail_dialog: false,
      perPage: null,
      total: null,
      search: {
        status: "pending",
      },
    };
  },
  mounted() {
    this.__get();
    this.__getLeaveCategories();
    this.getAcademicYears();
    this.getLevels();
    this.getAllPrograms();
  },
  computed: {},
  methods: {
    getApplicable_users(users) {
      return users.split(",");
    },
    leavestatus(status) {
      if (status) {
        switch (status) {
          case "pending":
            return "badge-warning";
            break;
          case "approved":
            return "badge-success";
            break;
          case "decline":
            return "badge-danger";
            break;
        }
      } else {
        return "badge-warning";
      }
    },
    __get() {
      this.loadingData = true;
      leaveRequestService
        .paginate(this.search)
        .then((response) => {
          this.leave_requests = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch((error) => {
          // console.log(error);
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    __add() {
      this.$refs["leave_category"].showModal();
    },
    __edit(id) {
      this.$refs["leave_category"].showModal(id);
    },

    __detail(leave, status = "pending") {
      this.leave_detail_dialog = true;
      this.leave_detail = leave;
      this.leave_detail.status = status;
    },
    __viewDetail(leave) {
      this.detail_dialog = true;
      this.leave_detail = leave;
    },
    __reset() {
      this.leave_detail_dialog = false;
      this.leave_detail = {};
    },
    __update() {
      let fd = this.leave_detail;
      this.isBusy = true;
      leaveRequestService
        .update(this.leave_detail.id, fd)
        .then((response) => {
          this.isBusy = false;
          this.$snotify.success("Information updated");
          this.__reset();
          this.__get();
        })
        .catch((error) => {
          this.isBusy = false;
          // console.log(error);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    __getLeaveCategories() {
      leaveCategoryService
        .paginate()
        .then((response) => {
          this.leave_catgories = response.data.data;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    generateFd() {
      // console.log(this.leave_request)
      let fd = new FormData();
      for (let key in this.leave_detail) {
        if (key == "is_half" && this.leave_detail["is_half"]) {
          fd.append("is_half", this.leave_detail ? 1 : 0);
        } else {
          if (this.leave_detail[key] != null) {
            fd.append(key, this.leave_detail[key]);
          }
        }
      }
      return fd;
    },
    resetFilter() {
      this.search = {};
      this.search.status = "pending";
      this.__get();
    },
    __delete(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            leaveRequestService
              .delete(id)
              .then((response) => {
                this.$snotify.success("Successfully deleted");
                this.__get();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
    openSummaryPage(leave_category) {
      this.$router.push({
        name: "students-summary",
        params: {
          id: leave_category.user_id,
          type: leave_category.user_type,
        },
      });
    },
  },
};
</script>
<style scoped>
</style>
